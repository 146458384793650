<template>
  <div>
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'AddCustomer'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('AddCustomer')">

            <form-wizard ref="wizard"
                         @on-complete="formComplete"
                         shape="circle"
                         color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
                         finishButtonText="Submit">
              <wizard-step
                slot-scope="props"
                slot="step"
                :tab="props.tab"
                :transition="props.transition"
                :index="props.index">
              </wizard-step>
              <tab-content title="Step 1" class="mb-5" :before-change="beforeStep1">

                <div class="vx-row flex">
                  <!-- tab 1 content -->

                  <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-row mb-2">
                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('FirstName')"
                          class="w-full" :label-placeholder="$t('FirstName') + '*'"
                          v-model="firstName"/>
                        <span class="text-danger text-sm">{{ errors.first($t('FirstName')) }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('LastName')"
                          class="w-full" :label-placeholder="$t('LastName') + '*'"
                          v-model="lastName"/>
                        <span class="text-danger text-sm">{{ errors.first($t('LastName')) }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('Company')"
                          class="w-full" :label-placeholder="$t('Company') + '*'"
                          v-model="company"/>
                        <span class="text-danger text-sm">{{ errors.first('Company') }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('Address')"
                          class="w-full" :label-placeholder="$t('Address') + '*'"
                          v-model="address"/>
                        <span class="text-danger text-sm">{{ errors.first($t('Address')) }}</span>
                      </div>
                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('HouseNo')"
                          class="w-full" :label-placeholder="$t('HouseNo') + '*'"
                          v-model="houseNo"/>
                        <span class="text-danger text-sm">{{ errors.first($t('HouseNo')) }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          name="postal-code"
                          type="number"
                          class="w-full" :label-placeholder="$t('PostalCode') + '*'"
                          v-model="postalCode"/>
                        <span class="text-danger text-sm">{{ errors.first('postal-code') }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          name="city"
                          class="w-full" :label-placeholder="$t('City') + '*'"
                          v-model="city"/>
                        <span class="text-danger text-sm">{{ errors.first('city') }}</span>
                      </div>


                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          name="country"
                          class="w-full" :label-placeholder="$t('Country') + '*'"
                          v-model="country"/>
                        <span class="text-danger text-sm">{{ errors.first('country') }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          name="mobile"
                          class="w-full" :label-placeholder="$t('Mobile') + '*'"
                          v-model="mobile"/>
                        <span class="text-danger text-sm">{{ errors.first('mobile') }}</span>
                      </div>
                      <div class="vx-col w-full mt-3">
                        <vs-input
                          name="mobile1"
                          class="w-full" :label-placeholder="$t('AlternativeMobile1')"
                          v-model="mobile1"/>
                        <span class="text-danger text-sm">{{ errors.first('mobile1') }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          name="mobile2"
                          class="w-full" :label-placeholder="$t('AlternativeMobile2')"
                          v-model="mobile2"/>
                        <span class="text-danger text-sm">{{ errors.first('mobile2') }}</span>
                      </div>



                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'numeric'"
                          validate-on="change"
                          :name="$t('Landline')"
                          class="w-full" :label-placeholder="$t('Landline')"
                          type="number"
                          v-model="landline"/>
                        <span class="text-danger text-sm">{{ errors.first($t('Landline')) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-row mb-2">

                      <template v-if="activeUserInfo.account_role == 0">

                        <div class="vx-col w-full mt-3">
                          <vs-input
                            validate-on="change"
                            :name="$t('Director')"
                            class="w-full" :label-placeholder="$t('Director')"
                            v-model="directorName"/>
                          <span class="text-danger text-sm">{{ errors.first($t('Director')) }}</span>
                        </div>

                        <div class="vx-col w-full mt-3">
                          <vs-input
                            validate-on="change"
                            :name="$t('HRBNr')"
                            class="w-full" :label-placeholder="$t('HRBNr')"
                            v-model="hrbNr"/>
                          <span class="text-danger text-sm">{{ errors.first($t('HRBNr')) }}</span>
                        </div>

                        <div class="vx-col w-full mt-3">
                          <vs-input
                            validate-on="change"
                            :name="$t('IBan')"
                            class="w-full" :label-placeholder="$t('IBan')"
                            v-model="iban"/>
                          <span class="text-danger text-sm">{{ errors.first($t('IBan')) }}</span>
                        </div>

                        <div class="vx-col w-full mt-3">
                        <vs-input
                          validate-on="change"
                          :name="$t('BIC')"
                          class="w-full" :label-placeholder="$t('BIC')"
                          v-model="bic"/>
                        <span class="text-danger text-sm">{{ errors.first($t('BIC')) }}</span>
                      </div>

                      </template>


                      <div class="vx-col w-full mt-3">
                        <vs-input
                          validate-on="change"
                          name="stnr"
                          class="w-full" :label-placeholder="$t('StNr') + '*'"
                          v-model="stNr"/>
                        <span class="text-danger text-sm">{{ errors.first('stnr') }}</span>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          validate-on="change"
                          name="ustldnr"
                          class="w-full" :label-placeholder="'ustldnr *'"
                          v-model="ustldnr"/>
                        <span class="text-danger text-sm">{{ errors.first('ustldnr') }}</span>
                      </div>


                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required|email'"
                          validate-on="change"
                          name="email"
                          class="w-full" :label-placeholder="$t('EmailId') + '*'"
                          v-model="email"/>
                        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                      </div>


                      <div class="vx-col w-full mt-3">
                        <vs-select
                          autocomplete
                          class=" w-full pb-3"
                          :label="$t('Referer')"
                          v-model="selectedReferer"
                          :placeholder="$t('Referer') + ' *'"
                        >
                          <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"
                                          v-for="(customer,index) in managerAccounts"/>
                        </vs-select>
                      </div>
<!--                      <div class="vx-col w-full mt-3">-->
<!--                        <vs-select-->
<!--                          autocomplete-->
<!--                          class=" w-full pb-3"-->
<!--                          :label="$t('Referer')"-->
<!--                          v-model="selectedReferer"-->
<!--                          :placeholder="$t('Referer') + ' *'"-->
<!--                        >-->
<!--                          <vs-select-item :key="index" :value="customer.user_id" :text="customer.user_name"-->
<!--                                          v-for="(customer,index) in (selectedManager == null ? [] : selectedManager.staff_accounts)"/>-->
<!--                        </vs-select>-->
<!--                      </div>-->

                      <div class="vx-col w-full mt-3">
                        <vs-select
                          autocomplete
                          class="pr-4 w-full pb-3"
                          :label="$t('Flag')"
                          v-model="selectedFlag"
                          placeholder="Flag"
                        >
                          <vs-select-item :key="index" :value="f.id" :text="f.name" v-for="(f,index) in flags"  />
                        </vs-select>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-select
                          autocomplete
                          class="pr-4 w-full pb-3"
                          :label="$t('BalanceType')"
                          v-model="selectedBalanceType"
                          :placeholder="$t('BalanceType')"
                        >
                          <vs-select-item :key="index" :value="f.id" :text="f.name" v-for="(f,index) in balanceTypeList"  />
                        </vs-select>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-checkbox v-model="isActive">Active
                        </vs-checkbox>
                      </div>

                      <div class="vx-row w-full mb-6 mt-6 mx-1">
                        <div class="vx-col w-full mt-3">
                          <vs-textarea :label="$t('Remarks')"
                                       v-model="remarks"/>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </tab-content>

              <!-- tab 2 content -->
              <tab-content title="Step 2" class="mb-5" :before-change="beforeStep2">
                <div class="vx-row">
                  <!-- tab 2 content -->
                  <div class="vx-col md:w-1/2 flex-1">
                    <div class="vx-row mb-2">

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required|min:3'"
                          validate-on="change"
                          :name="$t('Username')"
                          class="w-full" :label-placeholder="$t('Username') + '*'"
                          @change="checkUserDebounceTime"
                          v-model="username"/>
                        <span class="text-danger text-sm">{{ errors.first($t('Username')) }}</span>
                        <span class="text-danger text-sm" v-if="!usernameAvailable">{{ $t('UsernameNotAvailable') }}</span>
                      </div>

                      <div class="vx-col w-full mt-3" >
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('Password')"
                          @input="passCheck"
                          class="w-full" :label-placeholder="$t('Password') + '*'"
                          type="password"
                          v-model="password"/>
                        <span class="text-danger text-sm">{{ errors.first($t('Password')) }}</span>

                      </div>

                      <div class="vs-col px-3">
                        <p class="text-sm pass-check-item" :class="[passStrengthValue.caps ? 'text-success' : 'text-gray']">{{ $t('UppercaseLetter') }}</p>
                        <p class="text-sm pass-check-item" :class="[passStrengthValue.small ? 'text-success' : 'text-gray']">{{ $t('LowercaseLetter') }}</p>
                        <p class="text-sm pass-check-item" :class="[passStrengthValue.numbers ? 'text-success' : 'text-gray']">{{ $t('Number') }}</p>
                        <p class="text-sm pass-check-item" :class="[passStrengthValue.special ? 'text-success' : 'text-gray']">{{ $t('OneSymbol') }}</p>
                        <p class="text-sm pass-check-item" :class="[passStrengthValue.length ? 'text-success' : 'text-gray']">{{ $t('EightCharacters') }}</p>
                      </div>

                      <div class="vx-col w-full mt-3">
                        <vs-input
                          v-validate="'required'"
                          validate-on="change"
                          :name="$t('ConfirmPassword')"
                          type="password"
                          class="w-full" :label-placeholder="$t('ConfirmPassword') + '*'"
                          v-model="confirmPassword"/>
                        <span
                          class="text-danger text-sm">{{ errors.first($t('ConfirmPassword')) }}</span>
                      </div>

                      <div class="vx-row vx-col  w-fullmt-3 ml-0 mr-0 flex-1" style="place-items: center;">
                        <vs-select autocomplete v-model="accountRole" class="w-full select-large"
                                   :label="$t('CustomerType')">
                          <vs-select-item :key="index" :value="item.value" :text="item.name"
                                          v-for="(item,index) in rolesList" class="w-full"/>
                        </vs-select>
                      </div>


                      <div class="vx-col w-full mt-3" v-if="accountRole != 2">
                        <vs-input
                          validate-on="change"
                          :name="$t('Domain')"
                          class="w-full" :label-placeholder="$t('Domain')"
                          v-model="subDomain"/>
                        <span
                          class="text-danger text-sm">{{ errors.first($t('Domain')) }}</span>
                      </div>

                      <div class="vx-row vx-col mt-3 ml-0 mr-0 flex-1" style="place-items: center;">

                        <div class="vs-row flex py-2">
                          <vs-switch color="dark" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash" v-model="enableWebView">
                            <span slot="on">{{ $t('YES') }}</span>
                            <span slot="off">{{ $t('NO') }}</span>
                          </vs-switch>
                          <p class="px-3">{{ $t('EnableWebSales') }}</p>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </tab-content>

              <tab-content title="Step 3" class="mb-5">
                <div class="vx-row flex">
                  <!-- tab 3 content -->
                  <div class="vx-col ml-3 flex-1 text-center">
                    <vs-icon icon="check_circle" size="110px" color="rgba(var(--vs-success), 1)" />
                    <div class="p-6"></div>
                    <p>{{ $t('AddedCustomerSuccessfully') }}</p>
                    <div class="p-6"></div>
                    <vs-button @click="openCustomerList">{{ $t('OK') }}</vs-button>
                  </div>
                </div>
              </tab-content>

              <!--              Custom Footer In Tab-->
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <vs-button v-if="props.activeTabIndex > 0 && !props.isLastStep"
                             @click.native="props.prevTab()" :style="props.fillButtonStyle">
                    {{ $t('Previous') }}
                  </vs-button>
                </div>
                <div class="wizard-footer-right">
                  <vs-button v-if="!props.isLastStep" @click.native="props.nextTab()"
                             class="wizard-footer-right" :style="props.fillButtonStyle">
                    {{ $t('Next') }}
                  </vs-button>

<!--                  <vs-button v-else @click.native="formComplete"-->
<!--                             class="wizard-footer-right finish-button"-->
<!--                             :style="props.fillButtonStyle"> {{props.isLastStep ? 'Done' : 'Next'}}-->
<!--                  </vs-button>-->
                </div>
              </template>
            </form-wizard>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: 'AddCustomer',
  components: {
    'v-select': vSelect,
  },
  computed: {
    rolesList() {
      let list = []
      if (localStorage.getItem('role') == 0) {
        list = [
          {
            name: 'Reseller',
            value: 1,
          },
          {
            name: 'Seller',
            value: 2,
          },
        ]
      }
      if (localStorage.getItem('role') == 1) {
        list = [
          {
            name: 'Seller',
            value: 2,
          },
        ]
      }
      return list
    },
    validateStep1() {
      return this.lastName !== '' && this.firstName !== ''
          && this.company !== '' && this.address !== '' && this.postalCode !== '' && this.city !== ''
          && this.mobile !== '' && (this.stNr !== '' || this.ustldnr !== '')
          && this.email !== '' && this.country !== ''
    },
    validateStep2() {
      return !this.errors.any() && this.username !== '' && this.password !== ''
          && this.confirmPassword !== '' && this.accountRole !== null && this.usernameAvailable
    },
    isLastStep() {
      if (this.$refs.wizard) {
        return this.$refs.wizard.isLastStep
      }
      return false
    },
    users() {
      return this.$store.state.user.users
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  data() {
    return {
      managerAccounts: [],
      interval: null,
      usernameAvailable: true,
      firstName: '',
      lastName: '',
      company: '',
      houseNo: '',
      address: '',
      postalCode: '',
      city: '',
      mobile: '',
      mobile1: '',
      mobile2: '',
      landline: '',
      stNr: '',
      ustldnr: '',
      email: '',
      selectedReferer: null,
      selectedFlag: 2,
      country: 'Germany',
      remarks: '',
      username: '',
      password: '',
      subDomain: '',
      confirmPassword: '',
      accountRole: null,
      isActive: true,
      enableWebView: true,
      selectedBalanceType: 1,
      hrbNr: '',
      iban: '',
      bic: '',
      directorName: '',
      balanceTypeList: [
        {
          id: 0,
          name: 'Plus System',
        },
        {
          id: 1,
          name: 'Minus System',
        },
      ],
      flags: [
        {
          id: 0,
          name: 'Red',
        },
        {
          id: 1,
          name: 'Green',
        },
        {
          id: 2,
          name: 'Yellow',
        },
      ],
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Customers', i18n: 'Customers', url: '/customers/view' },
        { title: 'Add Customer', i18n: 'AddCustomer', active: true },
      ],
      passStrengthValue: {
        'caps': false,
        'length': false,
        'special': false,
        'numbers': false,
        'small': false,
      },
    }
  },
  methods: {
    openCustomerList() {
      this.$router.push({ name: 'customers-view' })
    },
    passCheck(value) {
      let caps = false
      let numbers = false
      let small = false
      let special = false
      for (let index = 0; index < this.password.length; index++) {
        const char = this.password.charCodeAt(index)
        if (char >= 65 && char <= 90) {
          caps = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if (char >= 97 && char <= 122) {
          small = true
        } else if (char >= 48 && char <= 57) {
          numbers = true
        } else if ([33, 35, 36, 37, 38, 64, 42, 63, "33", "35", "36", "37", "38", "64", "42", "63"].includes(char)) {
          special = true
        }
      }
      this.passStrengthValue.caps = caps
      this.passStrengthValue.numbers = numbers
      this.passStrengthValue.small = small
      this.passStrengthValue.special = special
      if (this.password.length < 8) {
        this.passStrengthValue.length = false
      } else {
        this.passStrengthValue.length = true
      }
      const obj = { ...this.passStrengthValue }
      this.passStrengthValue = {}
      this.passStrengthValue = { ...obj }
    },
    async beforeStep2() {
      this.$validator.validate()
      if (this.validateStep2) {
        let passValid = true
        Object.keys(this.passStrengthValue).forEach((k) => {
          if (this.passStrengthValue[k] == false) {
            passValid = false
          }
        })

        if (!passValid) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Password not valid',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return
        }
        if (this.accountRole != 2 && this.subDomain == '') {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Please fill domain',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
        if (this.password !== this.confirmPassword) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Password not matched',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }

        const payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          company: this.company,
          house_no: this.houseNo,
          address: this.address,
          postal: this.postalCode,
          city: this.city,
          mobile: this.mobile,
          mobile_1: this.mobile1,
          mobile_2: this.mobile2,
          land_line: this.landline,
          stnr: this.stNr,
          ustldnr: this.ustldnr,
          email: this.email,
          country: this.country,
          remarks: this.remarks,
          username: this.username,
          password: this.password,
          sub_domain: this.subDomain,
          account_role: this.accountRole,
          hrb_nr: this.hrbNr,
          iban: this.iban,
          bic: this.bic,
          director_name: this.directorName,
          // refer_id: this.selectedReferer,
          // refer_manager_id: this.selectedManager.user_id,
          flag: this.selectedFlag,
          is_active: this.isActive ? 1 : 0,
          enable_web_view: this.enableWebView ? 1 : 0,
          balance_type: this.selectedBalanceType,
        }

        if (this.selectedReferer) {
          payload.refer_id = this.selectedReferer
          // payload.refer_manager_id = this.selectedManager.user_id
        }
        if (this.accountRole != 2) payload.subdomain = this.subDomain
        this.$vs.loading()
        const data = await this.$store.dispatch('customer/addNewCustomer', payload).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
        if (data) {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Success',
            text: data.data.message,
            iconPack: 'feather',
            icon: 'icon-check-circle',
            color: 'success',
          })
          return true
        }
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      }
      return false
    },
    async beforeStep1() {
      this.$validator.validate()
      if (!(this.stNr !== '' || this.ustldnr !== '')) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please fill St.Nr or ustldnr',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      }

      if (!this.mobile.startsWith('+')) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please correct mobile no with country code (+xx)',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return false
      }
      if (this.mobile1) {
        if (!this.mobile1.startsWith('+')) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Please correct alternative mobile no with country code (+xx)',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
      }
      if (this.mobile2) {
        if (!this.mobile2.startsWith('+')) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: 'Please correct alternative mobile no with country code (+xx)',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        }
      }
      return this.validateStep1
    },
    formComplete() {
      if (this.validateStep3) {

      }
      return false
    },
    loadUsers() {
      this.$vs.loading()
      this.$store.dispatch('user/fetchMangerUsers', {})
        .then((data) => {
          this.managerAccounts = data.data.staff_accounts
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    checkUserDebounceTime() {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.checkUsername()
      }, 500)
    },
    checkUsername() {
      this.usernameAvailable = true
      this.$store.dispatch('user/checkCustomerUsername', this.username)
        .then((data) => {
          if (data.message_type == 1) {
            this.usernameAvailable = true
          } else {
            this.usernameAvailable = false
          }
        })
        .catch((err) => {
          if (err.response.data.message_type == 0) {
            this.usernameAvailable = false
          }
        })
    },
  },
  mounted() {
    if (localStorage.getItem('role') == 1) {
      this.accountRole = 2
    }
    this.loadUsers()
  },
}
</script>

<style scoped>
  .c-center {
    margin: auto;
  }

  .c-center-text {
    text-align: center;
  }

  .feather-csv {
    width: 50px;
    height: 50px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }

  .p-type {
    padding: 0 1rem;
    font-size: 10px;
  }

  .p-type-select {
    padding: 0 0 0 1rem;
  }

  .upload-btn-wrapper {
    margin: 1rem;
    position: relative;
    display: flex;
  }

  .upload-contents {
    border: 1px dashed #00000040;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
    color: #2C2C2C;
    background-color: white;
    padding: 30px 30px;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    margin: auto;
  }

  .upload-btn-wrapper-denomination {
    position: absolute;
  }

  .upload-contents-denomination {
    padding: 5px;
  }

  .upload-btn-wrapper input[type=file] {
    position: absolute;
    margin: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }

  .p-image {
    width: 90%;
    object-fit: cover;
    max-height: 200px;
    margin: 1rem;
  }

  .p-image-denomination {
    width: 50%;
  }
</style>
